import React from "react"
import Layout from "../component/Layout"
// import Img from "gatsby-image"
import "../styles/pages/blog.scss"
import { Link, graphql, useStaticQuery } from "gatsby"
import SEO from "../component/SEO"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDorlandsHolidaysBlog(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            title

            # photo {
            #   id
            # }
            slug
            publishedDate(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)

  // BLOG LIST VIEW
  return (
    <Layout>
      <SEO title="Blog" />

      <section className="hero-content blog">
        <div className="hero-title">
          <div className="inner-wrap">
            <h1>Blog</h1>
            <h2>Get The Latest News</h2>
          </div>
        </div>
      </section>
      <section className="blog-page">
        <article className="blog-article">
          <ol>
            {data.allContentfulDorlandsHolidaysBlog.edges.map(edge => {
              return (
                <li>
                  <Link to={`/blog/${edge.node.slug}`}>
                    {/* {edge.node.photo.id} */}
                    <h3>{edge.node.title}</h3>
                    <p>
                      <i>{edge.node.publishedDate}</i>
                    </p>
                  </Link>
                </li>
              )
            })}
          </ol>
        </article>
      </section>
    </Layout>
  )
}

export default BlogPage
